.gallery-container {
  margin: 0 auto;
  max-width: 1600px;
}

.gallery-item {
  position: relative;
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
  display: block;
  border-radius: 0.5rem;
  overflow: hidden;
}

.gallery-item:hover {
  transform: scale(1.02);
}

.gallery-item img {
  transition: opacity 0.2s ease-in-out;
  background-color: #f0f0f0;
}

.gallery-item img[src*="data:image/svg+xml"] {
  opacity: 0.5;
  padding: 1rem;
  object-fit: contain !important;
}

/* Prevent broken image icons */
.gallery-item img::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #f0f0f0;
}

/* Override default LightGallery animations */
.lg-css3.lg-slide.lg-use-css3 .lg-item {
  opacity: 0;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  opacity: 1;
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-prev-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-next-slide,
.lg-css3.lg-slide.lg-use-css3 .lg-item.lg-current {
  -webkit-transition: -webkit-transform 0.2s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.2s ease 0s;
  transition: transform 0.2s cubic-bezier(0, 0, 0.25, 1) 0s, opacity 0.2s ease 0s;
}

.lg-backdrop {
  background-color: rgba(0, 0, 0, 0.95) !important;
  transition: background-color 0.2s ease-in-out !important;
}

.lg-outer * {
  transition-timing-function: ease-out !important;
}

.lg {
  transition: transform 0.2s ease-out !important;
}

.lg-toolbar {
  background-color: transparent !important;
}

.lg-counter {
  color: #fff !important;
}

.lg-video-cont {
  max-width: 1200px !important;
  margin: 0 auto;
}

.lg-video-object {
  width: 100% !important;
  height: 100% !important;
}

/* Custom video play button */
.lg-video-play-button {
  padding: 18px !important;
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-radius: 50% !important;
  transition: transform 0.2s ease-in-out !important;
}

/* Thumbnails customization */
.lg-thumb-outer {
  background-color: rgba(0, 0, 0, 0.45) !important;
  height: 90px !important;
}

.lg-thumb-item {
  border: 2px solid transparent !important;
  border-radius: 4px !important;
  transition: all 0.2s ease-in-out !important;
}

.lg-thumb-item.active,
.lg-thumb-item:hover {
  border-color: #fff !important;
}

/* Navigation arrows */
.lg-prev,
.lg-next {
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-radius: 50% !important;
  color: #fff !important;
  transition: all 0.2s ease-in-out !important;
}

.lg-prev:hover,
.lg-next:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
  transform: scale(1.1) !important;
}

/* Close button */
.lg-close {
  background-color: rgba(0, 0, 0, 0.6) !important;
  border-radius: 50% !important;
  color: #fff !important;
  transition: all 0.2s ease-in-out !important;
}

.lg-close:hover {
  background-color: rgba(0, 0, 0, 0.8) !important;
  transform: scale(1.1) !important;
}

/* Error state handling */
.lg-error-msg {
  background-color: rgba(0, 0, 0, 0.8) !important;
  color: #fff !important;
  padding: 1rem !important;
  border-radius: 0.5rem !important;
}

/* Loading state */
.lg-loading {
  background-color: rgba(0, 0, 0, 0.8) !important;
  border-radius: 50% !important;
}

/* Ensure toolbar and controls are always visible */
.lg-toolbar {
  opacity: 1 !important;
  visibility: visible !important;
  background-color: rgba(0, 0, 0, 0.45) !important;
}

.lg-toolbar .lg-download {
  display: block !important;
  opacity: 1 !important;
  visibility: visible !important;
}

.lg-toolbar-visible .lg-toolbar {
  opacity: 1 !important;
  visibility: visible !important;
}

@media (max-width: 768px) {
  .lg-toolbar {
    opacity: 1 !important;
    visibility: visible !important;
    background-color: rgba(0, 0, 0, 0.45) !important;
  }

  .lg-toolbar .lg-download {
    display: block !important;
    opacity: 1 !important;
    visibility: visible !important;
  }

  .lg-toolbar .lg-icon {
    color: #fff !important;
  }
} 

.lg-video-poster {
  object-fit: contain !important;
  width: auto !important;
  height: auto !important;
  max-width: 100% !important;
  max-height: 100% !important;
  margin: 0 auto !important;
}

.lg-video-container {
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
}

.lg-video-poster-fix .lg-video-poster {
  object-fit: contain !important;
  background-size: contain !important;
}

.lg-video-cont.lg-has-html5 {
  margin-top: -40px !important;
  height: 70% !important;
}

